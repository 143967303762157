<template>
    <div class="reqlist"
         v-infinite-scroll="loadItems"
         infinite-scroll-disabled="loading"
         :infinite-scroll-immediate-check="true"
         infinite-scroll-distance="10" >
        <div v-for="(item,index) in reqs" :key="index" class="item">
<!--            头部信息-->
            <div class="header">
                <div class="p1">
                    <div class="company">{{item.postName}}</div>
                    <div class="location">
                        <img src="assets/location.png" style="width: 12px;height: 12px;"/>
                        <div class="p3">{{item.address}}</div>
                    </div>
                </div>
                <div class="p2" v-on:click="toReqDetail(item)">了解详情</div>
            </div>

<!--            内容-->
            <div class="content">
                <div class="content_item">
                    <div class="p6">服务日期</div>
                    <div class="p4">{{item.time}}</div>
                    <div class="p5">{{item.categoryName}}</div>
                </div>
                <div class="content_item">
                    <img v-for="(imgItem,index) in item.pictures" :key="'img'+index" :src="imgItem" />
<!--                    <img src="assets/demo_i1.png"/>-->
<!--                    <img src="assets/demo_i2.png" />-->
<!--                    <img src="assets/demo_i3.png" />-->
                </div>
            </div>

        </div>
        <div v-if="reqs.length==0 && !loading " class="none">
            <img src="assets/none.png" />
            <div>暂无内容</div>
        </div>
        <div v-show="loading"  style="text-align: center;font-size: 13px;width: 100%;height:26px;">加载中...</div>


<!--        返回home-->
        <div class="reqlist_back_home" v-on:click="toHome">
            <img src="assets/back_home.png"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReqList",
        components:{

        },
        data(){
            return {
                loading:false,
                reqs:[],
                size:10,
                currentIdx:1,
            }
        },
        mounted() {
            this.init();
        },
        methods:{
            init(){
                document.title="投标列表";
                this.loadItems();
            },
            loadItems(){
                if(this.loading) return;
                this.loading=true;

                setTimeout(() => {
                    let reqUrl="/api/product/pageH5";
                    let cata=this.$route.query.cardType;
                    let data={category:cata,current:this.currentIdx,size:this.size};
                    this.$post(reqUrl,data)
                        .then(rsp=>{
                            try{
                                if(rsp.code==200 && rsp.success){
                                    let rows=rsp.data.rows;
                                    rows.forEach(t=>{
                                        this.reqs.push(t);
                                    });

                                    this.currentIdx=this.currentIdx+1;
                                }
                            }
                            catch (e) {
                                console.log(e);
                            }
                        })
                        .catch(e=>{
                            console.log(e);
                        })
                    this.loading = false;
                }, 50);

            },
            toHome(){
                this.$router.push({path:'/'});
            },
            toReqDetail(item){
                this.$router.push({path:'/ReqDetail',name:'ReqDetail',query:{
                        pid:item.id,
                        cardType:this.$route.query.cardType
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .reqlist{
        width: 750px;
    }

    .reqlist .reqlist_back_home{
        width: 100px;
        height: 100px;
        background-color: white;
        border-radius: 100px;
        position: fixed;
        bottom: 30px;
        right: 30px;
        box-shadow: 0px 2px 4px rgba(100,100,100,0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .reqlist .reqlist_back_home img{
        width: 50px;
        height: 50px;
    }

    .reqlist .item{
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 2px 20px rgba(100,100,100,0.5);
    }

    .reqlist .item .header{
        display: flex;
        flex-direction: row;
        letter-spacing: 2px;
        align-items: center;
        margin-bottom: 16px;

    }

    .reqlist .item .header .p1{
        flex:1;
        margin-left: 30px;
        margin-top: 16px;
    }

    .reqlist .item .header .p1 .company{
        font-size: 26px;
        font-weight: bold;
    }

    .reqlist .item .header .p1 .location{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
    }

    /*.reqlist .item .header .p1 .location img{*/
    /*    width: 24px;*/
    /*    height: 24px;*/
    /*}*/

    .reqlist .item .header .p1 .location .p3{
        font-size: 22px;
        color: #A79F9F;
        margin-left: 10px;
    }

    .reqlist .item .header .p2{
        margin-right: 30px;
        color: #226DF8;
        font-size: 24px;
    }

    .reqlist .item .content{
        border-top:2px #E4E4E4 dashed;
        padding-bottom: 16px;
    }
    .reqlist .item .content .content_item{
        margin-left: 30px;
        display: flex;
        flex-direction: row;
        font-size: 26px;
        margin-top: 16px;
    }

    .reqlist .item .content .content_item .p6{
        color: #A79F9F;
    }

    .reqlist .item .content .content_item .p4{
        flex:1;
        margin-left: 16px;
    }

    .reqlist .item .content .content_item .p5{
        margin-right: 30px;
    }

    .reqlist .item .content .content_item img{
        width: 150px;
        height: 150px;
        margin-right: 20px;
    }

    .none{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #666666;
        font-size: 28px;
        margin-top: 220px;
    }
    .none img{
        width: 200px;
        height: 200px;
    }
</style>
